import {
  EnvironmentInjector,
  EnvironmentProviders,
  inject,
  InjectionToken,
  makeEnvironmentProviders,
  runInInjectionContext,
} from '@angular/core';
import { HttpClient, HttpHandler, HttpInterceptorFn, HttpRequest } from '@angular/common/http';

import { ApiConfig } from './types/api-config.type';
import { apiDataAdapterBaseUrlInterceptorFactory } from './api-data-adapter-base-url.interceptor';

export const provideHttpApiClient = (
  token: InjectionToken<HttpClient>,
  apiConfig: ApiConfig,
  interceptors?: HttpInterceptorFn[],
): EnvironmentProviders =>
  makeEnvironmentProviders([
    {
      provide: token,
      useFactory: httpApiClientFactory(apiConfig, interceptors),
    },
  ]);

const httpApiClientFactory = (apiConfig: ApiConfig, httpApiClientInterceptors?: HttpInterceptorFn[]) => () => {
  const httpHandler = inject(HttpHandler);
  const injector = inject(EnvironmentInjector);

  const initialChainFunction: HttpInterceptorFn = (request, handler) => handler(request);
  const interceptors = [apiDataAdapterBaseUrlInterceptorFactory(apiConfig), ...(httpApiClientInterceptors ?? [])];

  const chain = interceptors.reduceRight(interceptorsChainReducerFactory(injector), initialChainFunction);

  return new HttpClient({ handle: (req: HttpRequest<unknown>) => chain(req, (req) => httpHandler.handle(req)) });
};

const interceptorsChainReducerFactory =
  (injector: EnvironmentInjector) =>
  (next: HttpInterceptorFn, interceptor: HttpInterceptorFn): HttpInterceptorFn =>
  (request, handler) => {
    return runInInjectionContext(injector, () => interceptor(request, (downstreamRequest) => next(downstreamRequest, handler)));
  };
