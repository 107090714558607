<ng-container *transloco="let t; read: 'COMMON.PAGE_NOT_ALLOWED'" data-test-id="pageNotAllowedContainer">
  <section>
    <chr-illustration name="guard-dog-security" />

    <span data-test-id="pageNotAllowedHeader">{{ t("HEADER") }}</span>
    <p data-test-id="pageNotAllowedDescription">
      {{ t("DESCRIPTION_1") }}
      <br />
      {{ t("DESCRIPTION_2") }}
    </p>
  </section>
</ng-container>
