import { createSelector } from '@ngrx/store';
import { taxDocsFeature } from './tax-docs.reducer';
import { collectionsSelectorsFactory } from '@ciphr/utils/collections';

const { selectTaxDcsState } = taxDocsFeature;
export const selectTaxDocCollections = createSelector(selectTaxDcsState, (state) => state.collections);

export const {
  selectCollectionList,
  selectCollectionListLoading,
  selectCollectionListOrdering,
  selectCollectionListPaging,
  selectEmptyCollectionState,
} = collectionsSelectorsFactory(selectTaxDocCollections);
