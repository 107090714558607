<chr-button-icon class="close-button" icon="x" palette="neutral" variant="invisible" (click)="toggleNavigationVisibility()" />

<chr-side-nav *transloco="let t"
              [auto-collapse]="true"
              [full-width]="handset()"
              [logo]="handset()"
              [uncollapsible]="handset()"
              (chr-action)="toggleNavigation()">
  @if (logo()) {
    <img [alt]="t('NAVIGATION.COMPANY_LOGO')" slot="logo" [src]="logo()" />
  }

  <!-- Search -->
  @if (!searchDisabled()) {
    @if (search(); as navSearch) {
      <chr-nav-group icon="magnifier" [label]="t('NAVIGATION.SEARCH')" slot="search" data-pendo-nav-item-type="search">
        <div role="menuitem" [attr.aria-label]="t('COMMON.ARIA.INPUTS.AUTOMATIC_SEARCH')">
          <chr-input
            [formControl]="searchControl"
            icon="magnifier"
            [placeholder]="t('COMMON.PLACEHOLDERS.SEARCH')"
            [attr.aria-roledescription]="'search'"
          />
        </div>
        <!-- Show navigation search results if not loading -->
        @if (!navSearch.loading) {
          @for (result of navSearch.results; track result) {
            <div>
              <p class="search-group-title">{{ result.application }}</p>
              @for (item of result.items; track item) {
                <div>
                  @if (item.url) {
                    <chr-nav-item [ciphrAppNavigationLink]="item.url" [description]="item.description" [label]="item.label" />
                  }
                </div>
              }
            </div>
          }
          <!-- Navigation no search result found -->
          @if (!navSearch.results.length && navSearch.searchValue) {
            <ciphr-no-results />
          }
        }
        <!-- Navigation search loading -->
        @if (navSearch.loading) {
          <div class="loader">
            <chr-spinner palette="accent" size="m" />
          </div>
        }
      </chr-nav-group>
    }
  }

  <!-- Top level navigation rendering -->
  @for (topLevel of navigationMenu(); track topLevel) {
    @if (topLevel.url) {
      <chr-nav-group
        [href]="topLevel.url"
        [icon]="getProductIcon(topLevel.application)"
        [label]="topLevel.fullName"
        [short-label]="topLevel.shortName"
        data-pendo-nav-item-type="product"
        [attr.data-pendo-nav-product-id]="topLevel.application"
      />
    } @else {
      @if (topLevel.items?.length) {
        <chr-nav-group
          [icon]="getProductIcon(topLevel.application)"
          [label]="topLevel.fullName"
          [short-label]="topLevel.shortName"
          data-pendo-nav-item-type="product"
          [attr.data-pendo-nav-product-id]="topLevel.application"
        >
          <ng-container *ngTemplateOutlet="nestedGroupItems; context: { $implicit: topLevel.items }" />
        </chr-nav-group>
      }
    }
  }

  <!-- Recursive rendering of nested navigation items -->
  <ng-template #nestedGroupItems let-items>
    @for (level of items; track level) {
      <!-- No more nested items -->
      @if (level.url) {
        <chr-nav-item
          [ciphrAppNavigationLink]="level.url"
          #link="ciphrAppNavigationLink"
          [label]="level.label"
          [selected]="link.active"
          [attr.aria-current]="link.active"
        />
      } @else {
        @if (level.items?.length) {
          <chr-nav-tree [label]="level.label!">
            <ng-container *ngTemplateOutlet="nestedGroupItems; context: { $implicit: level.items }" />
          </chr-nav-tree>
        }
      }
      <!-- Has nested items -->
    }
  </ng-template>

  <!-- Privacy -->
  <div slot="privacy">
    <ng-content select="[privacy]" />
  </div>

  <chr-nav-group [label]="username()" slot="user" data-pendo-nav-item-type="profile-menu">
    <chr-avatar ciphrAvatar slot="icon" [value]="username()" />

    @if (languages.length > 1) {
      <chr-nav-tree [label]="activeLanguageLabel()">
        @for (language of languages; track language) {
          <chr-nav-item [icon]="getLanguageIcon(language.id)" [label]="language.label" (chr-action)="selectActiveLanguage(language.id)" />
        }
      </chr-nav-tree>
    }

    <chr-nav-item
      [ciphrAppNavigationLink]="authIssuer + '/profile'"
      #link="ciphrAppNavigationLink"
      [selected]="link.active"
      icon="user-crossed"
      [label]="t('NAVIGATION.PROFILE')"
    />
    @if (isSsoAdmin()) {
      <chr-nav-item
        [ciphrAppNavigationLink]="authIssuer + '/admin'"
        #link="ciphrAppNavigationLink"
        [selected]="link.active"
        icon="user-cog"
        [label]="t('NAVIGATION.ADMIN')"
      />
    }

    @if (desktopVersionUrl(); as url) {
      <chr-nav-item [href]="url" icon="monitor" [label]="t('NAVIGATION.DESKTOP')" />
    }

    <chr-nav-item icon="exit" [label]="t('NAVIGATION.LOG_OUT')" (chr-action)="logOut()" />
  </chr-nav-group>
</chr-side-nav>
