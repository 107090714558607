import { HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ListDateRangeParams, QueryParamsFilterParameters } from '@ciphr/utils/interfaces';
import { Payslip, PayslipsListItem } from '@ciphr/domains/payslips/models';
import { PayslipsDataAdapter, PayslipState } from '@ciphr/domains/payslips/state';
import { buildCollectionQuery, orderBy } from '@ciphr/utils/collections';
import { HTTP_API_CLIENT } from '@ciphr/utils/data-adapters';

@Injectable()
export class PayslipsApiDataAdapterService implements PayslipsDataAdapter {
  private httpClient = inject(HTTP_API_CLIENT);

  downloadPayslip(id: Payslip['id']): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`/payslips/${id}/download`, { observe: 'response', responseType: 'blob' as 'json' });
  }

  downloadPayslips(dateRange: ListDateRangeParams['dateRange']): Observable<HttpResponse<Blob>> {
    const queryParams: QueryParamsFilterParameters<ListDateRangeParams['dateRange']> = {
      $from: dateRange.from,
      $to: dateRange.to,
    };

    return this.httpClient.get<Blob>(`/payslips/download`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      params: new HttpParams({ fromObject: queryParams }),
    });
  }

  fetchCurrentPayslip(): Observable<Payslip> {
    return this.httpClient.get<Payslip>('/payslips/current');
  }

  fetchPayslip(id: string): Observable<Payslip> {
    return this.httpClient.get<Payslip>(`/payslips/${id}`);
  }

  fetchPayslipsList({
    orderBy: ordering,
    paging,
    dateRange,
  }: PayslipState['payslipsListParams']): Observable<{ items: PayslipsListItem[]; length: number }> {
    const queryParams = new HttpParams({
      fromObject: {
        ...buildCollectionQuery(orderBy(ordering)),
        $skip: (paging.pageIndex - 1) * paging.pageSize,
        $take: paging.pageSize,
        $from: dateRange.from,
        $to: dateRange.to,
      },
    });

    return this.httpClient.get<{ items: PayslipsListItem[]; length: number }>('/payslips', {
      params: queryParams,
    });
  }
}
