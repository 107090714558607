import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FileService {
  getFilenameFromContentDispositionHeader(contentDispositionHeader: string): string {
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = fileNameRegex.exec(contentDispositionHeader);
    let fileName = '';

    if (matches !== null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }

    return fileName;
  }

  downloadFileFromResponseBlob(headers: HttpHeaders, blob: Blob | null): void {
    const responseContentType = headers.get('Content-Type');
    const responseContentDisposal = headers.get('Content-Disposition');
    if (blob && responseContentType && responseContentDisposal) {
      const responseBlob = new Blob([blob], { type: responseContentType });
      const fileName = this.getFilenameFromContentDispositionHeader(responseContentDisposal);

      if (responseBlob.size && fileName.length) {
        this.saveAs(responseBlob, fileName);
      }
    }
  }

  saveAs(blob: Blob, filename: string): void {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
    a.remove();
  }
}
