import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { ApiConfig, provideHttpApiClient } from '@ciphr/utils/data-adapters';

import { APP_PERMISSIONS_HTTP_CLIENT } from './app-permissions-http-client.token';
import { appPermissionsInitializer } from './app-permissions-initializer.factory';

export const provideAppPermissions = (apiConfig: ApiConfig): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideHttpApiClient(APP_PERMISSIONS_HTTP_CLIENT, apiConfig),
    { provide: APP_INITIALIZER, useFactory: appPermissionsInitializer, multi: true },
  ]);
