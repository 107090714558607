import localeEnGb from '@angular/common/locales/en-GB';

import { ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideAppConfig } from '@ciphr/core/app-config';
import { AuthService, provideAuth } from '@ciphr/core/auth';
import { authConfig } from './auth.config';
import { AppNavigationApiDataAdapterService, provideAppNavigationApiDataAdapter } from '@ciphr/core/app-navigation/api-data-adapter';
import { provideAppNavigationState } from '@ciphr/core/app-navigation/state';
import { provideAppPermissions } from '@ciphr/core/app-permissions';
import { AuthContextApiDataAdapterService, provideAuthContextApiDataAdapterProvider } from '@ciphr/core/auth-context/api-data-adapter';
import { provideAuthContextState } from '@ciphr/core/auth-context/state';
import { provideStaticTranslation } from '@ciphr/shared/translation';
import { provideSensitiveDataState } from '@ciphr/shared/sensitive-data-toggle/state';
import { HTTP_API_CLIENT, provideHttpApiClient } from '@ciphr/utils/data-adapters';

import { SensitiveDataToggleLocalStorageAdapterService } from '@ciphr/shared/sensitive-data-toggle/local-storage-data-adapter';
import { GlobalErrorHandler } from '@ciphr/core/error-handler';
import { ChrComponentsModule } from '@ciphr-design-system/angular';
import { AUTH_PANEL_SERVICE } from '@ciphr/core/app-navigation/features';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';

import { MYPAY_HTTP_API_CLIENT } from './core/mypay-http-api-client.token';
import { AVATARS_API_URL } from '@ciphr/shared/avatar';
import { provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { provideHttpClient } from '@angular/common/http';
import { authContextInterceptor } from '@ciphr/core/auth-context/features';
import { translationConfig } from './translations/translation.config';
import { provideAppBranding } from '@ciphr/core/app-branding';
import { provideProfileApiDataAdapter } from '@ciphr/core/profile/data-adapters';

registerLocaleData(localeEnGb);

const apiConfig = { name: 'mypay', version: 1 };

export const appConfig: ApplicationConfig = {
  providers: [
    // Angular providers
    provideHttpClient(),
    provideRouter(appRoutes),

    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd/MMM/yyyy' } },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },

    // API client
    provideHttpApiClient(MYPAY_HTTP_API_CLIENT, apiConfig, [authContextInterceptor]),
    { provide: HTTP_API_CLIENT, useExisting: MYPAY_HTTP_API_CLIENT },

    // 3rd party providers
    provideStore(),
    provideStoreDevtools({ logOnly: !isDevMode() }),
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: { ...tooltipVariation, arrow: true, maxWidth: 'unset', placement: 'auto', theme: 'light' },
      },
    }),

    // design system
    importProvidersFrom(
      ChrComponentsModule.forRoot({
        components: true,
        defaultTheme: 'ciphr-light',
        iconsUrl: 'assets/icons',
        illustrationsUrl: 'assets/illustrations',
        polyfills: true,
      }),
    ),

    // core providers
    provideAppBranding(MYPAY_HTTP_API_CLIENT),
    provideAppConfig(),
    provideAuth(authConfig, ['/mypay/api/v1'], 'mypay'),
    { provide: AUTH_PANEL_SERVICE, useExisting: AuthService },

    provideAuthContextApiDataAdapterProvider({ apiConfig: apiConfig }),
    provideAuthContextState(AuthContextApiDataAdapterService),

    provideAppPermissions(apiConfig),

    provideAppNavigationApiDataAdapter(apiConfig),
    provideAppNavigationState(AppNavigationApiDataAdapterService),

    provideProfileApiDataAdapter(),

    provideStaticTranslation(translationConfig),
    provideSensitiveDataState(SensitiveDataToggleLocalStorageAdapterService),

    // value tokens
    { provide: AVATARS_API_URL, useValue: '/static/users-photos/' },
  ],
};
