import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { ApiDataAdapterModuleConfig, provideHttpApiClient } from '@ciphr/utils/data-adapters';

import { AuthContextApiDataAdapterService } from './auth-context-api-data-adapter.service';
import { AUTH_CONTEXT_HTTP_CLIENT } from './auth-context-http-client.token';
import { authContextInitializerFactory } from './auth-context-initializer.factory';

export const provideAuthContextApiDataAdapterProvider = ({ apiConfig, interceptors }: ApiDataAdapterModuleConfig): EnvironmentProviders =>
  makeEnvironmentProviders([
    AuthContextApiDataAdapterService,
    provideHttpApiClient(AUTH_CONTEXT_HTTP_CLIENT, apiConfig, interceptors || []),
    { provide: APP_INITIALIZER, useFactory: authContextInitializerFactory, multi: true },
  ]);
