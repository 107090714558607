import { inject, Injectable } from '@angular/core';

import { PersonalDetails } from '@ciphr/domains/personal-details/models';
import { PersonalDetailsDataAdapter } from '@ciphr/domains/personal-details/state';
import { HTTP_API_CLIENT } from '@ciphr/utils/data-adapters';

@Injectable()
export class PersonalDetailsApiDataAdapterService implements PersonalDetailsDataAdapter {
  private httpClient = inject(HTTP_API_CLIENT);

  fetchPersonalDetails() {
    return this.httpClient.get<PersonalDetails>(`/employee-personal-details`);
  }
}
