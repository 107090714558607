import { inject } from '@angular/core';
import { Observable, retry, tap } from 'rxjs';

import { Store } from '@ngrx/store';
import { AuthContextApiDataAdapterService } from './auth-context-api-data-adapter.service';
import { AuthContext } from '@ciphr/core/auth-context/models';
import { authContextFeatureActions } from '@ciphr/core/auth-context/state';

export const authContextInitializerFactory = () => {
  const authContextService = inject(AuthContextApiDataAdapterService);
  const store = inject(Store);

  return (): Observable<Array<AuthContext>> =>
    authContextService.fetchAuthContextsList().pipe(
      retry({ count: 10, delay: 500 }),
      tap((contextsList) => store.dispatch(authContextFeatureActions.setContextsList({ contextsList }))),
    );
};
