import { inject } from '@angular/core';

import { retry, tap } from 'rxjs';

import { TranslocoService } from '@ngneat/transloco';

import { TRANSLATION_HTTP_CLIENT } from './translation-http-client.token';

export const translationLanguagesInitializerFactory = () => {
  const httpClient = inject(TRANSLATION_HTTP_CLIENT);
  const translocoService = inject(TranslocoService);

  return () =>
    httpClient.get<{ code: string; name: string }[]>('/translation/languages').pipe(
      retry({ count: 10, delay: 500 }),
      tap((languages) => translocoService.setAvailableLangs(languages.map(({ code, name }) => ({ id: code, label: name })))),
    );
};
