import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnDestroy, OnInit, Signal } from '@angular/core';

import { Store } from '@ngrx/store';

import {
  authContextFeatureActions,
  selectActiveContext,
  selectAvailableContexts,
  selectAvailableContextsAmount,
} from '@ciphr/core/auth-context/state';

import { AuthContextLabelPipe } from './auth-context-label.pipe';
import { ChrComponentsModule } from '@ciphr-design-system/angular';
import { AuthContext } from '@ciphr/core/auth-context/models';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'ciphr-auth-context-selector',
  standalone: true,
  imports: [AuthContextLabelPipe, ChrComponentsModule, FormsModule],
  templateUrl: './auth-context-selector.component.html',
  styleUrls: ['./auth-context-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthContextSelectorComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  protected readonly JSON = JSON;

  managerContextsOnly = input(false, { transform: coerceBooleanProperty });

  activeContext = this.store.selectSignal(selectActiveContext);
  contexts: Signal<AuthContext[]> = this.store.selectSignal(selectAvailableContexts);
  hasMultipleContext = computed(() => this.store.selectSignal(selectAvailableContextsAmount)() > 1);

  ngOnInit(): void {
    this.managerContextsOnly() && this.store.dispatch(authContextFeatureActions.setManagerContextsOnly());
  }

  ngOnDestroy() {
    this.managerContextsOnly() && this.store.dispatch(authContextFeatureActions.resetManagerContext());
  }

  selectActiveContext(context: string): void {
    const parsedContext = JSON.parse(context);
    parsedContext.adminUserLoginName === null
      ? this.store.dispatch(authContextFeatureActions.selectActiveContext({ context: parsedContext }))
      : this.store.dispatch(authContextFeatureActions.selectActiveManagerContext({ context: parsedContext }));
  }
}
