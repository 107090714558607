import { inject } from '@angular/core';

import { filter, first, from, switchMap } from 'rxjs';

import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

import { APP_CONFIG } from '@ciphr/core/app-config';

export const authInitializerFactory = () => {
  const appConfigSource = inject(APP_CONFIG);
  const authConfig = inject(AuthConfig);
  const oAuthService = inject(OAuthService);

  return () =>
    appConfigSource.pipe(
      filter(Boolean),
      switchMap(({ clientId }) => {
        oAuthService.configure({ ...authConfig, clientId });
        return from(oAuthService.loadDiscoveryDocumentAndLogin());
      }),
      first(),
    );
};
