import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PaginatedData } from '@ciphr/utils/interfaces';
import { PayrollApprovalsStatus, PayrollListItem } from '@ciphr/domains/payroll-approval/models';
import { HttpErrorResponse } from '@angular/common/http';

export const payrollApprovalDataAdapterActions = createActionGroup({
  source: 'Payroll Approval Data Adapter',
  events: {
    'Payroll Approval Unapproved List Loaded Successfully': props<{ unapprovedList: PaginatedData<PayrollListItem> }>(),
    'Payroll Approval Approved List Loaded Successfully': props<{ approvedList: PaginatedData<PayrollListItem> }>(),
    'Payroll Approval Loaded Failed': props<{ approveState: PayrollApprovalsStatus; error: HttpErrorResponse }>(),
    'Payroll Approved Successfully': props<{ payroll: PayrollListItem }>(),
    'Payroll Approved Failed': emptyProps(),
  },
});
