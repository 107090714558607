import { EnvironmentProviders, makeEnvironmentProviders, Type } from '@angular/core';
import { authContextFeature } from './auth-context.reducer';
import { AuthContextDataAdapter } from './auth-context-data-adapter.interface';
import { provideEffects } from '@ngrx/effects';
import { AuthContextEffects } from './auth-context.effects';
import { provideState } from '@ngrx/store';
import { AUTH_CONTEXT_DATA_ADAPTER } from './auth-context-data-adapter.token';

export const provideAuthContextState = (dataAdapterClass: Type<AuthContextDataAdapter>): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideEffects([AuthContextEffects]),
    provideState(authContextFeature),
    { provide: AUTH_CONTEXT_DATA_ADAPTER, useClass: dataAdapterClass },
  ]);
