import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ChrComponentsModule } from '@ciphr-design-system/angular';
import { Store } from '@ngrx/store';
import { selectNotificationState, sensitiveDataToggleActions } from '@ciphr/shared/sensitive-data-toggle/state';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'ciphr-sensitive-data-alert',
  standalone: true,
  templateUrl: './sensitive-data-alert.component.html',
  styleUrls: ['./sensitive-data-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ChrComponentsModule, TranslocoDirective],
})
export class SensitiveDataAlertComponent implements OnInit {
  private store = inject(Store);

  selectSensitiveDataVisibility = this.store.selectSignal(selectNotificationState);

  ngOnInit() {
    this.store.dispatch(sensitiveDataToggleActions.loadSensitiveDataOption());
  }

  closeSensitiveDataToast(): void {
    this.store.dispatch(sensitiveDataToggleActions.dismissNotification());
  }
}
