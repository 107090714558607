import { ActiveFilter } from '@ciphr/shared/collections-filtering/models';

import { Ordering } from './ordering.type';
import { Paging } from './paging.type';

type QueryPropName = '$excludeIds' | '$orderBy' | '$search' | '$skip' | '$top';
type QueryObject = { [key in QueryPropName]?: boolean | number | string };
type QueryConstraint = (query: QueryObject) => QueryObject;

export const buildCollectionQuery = (...queryConstraints: QueryConstraint[]): QueryObject => {
  return queryConstraints.reduce((query, constraint) => constraint(query), {});
};

export const excludeIds =
  (ids: number[]) =>
  (query: QueryObject): QueryObject =>
    ids.length === 0 ? query : { ...query, $excludeIds: ids.join(',') };

export const filter =
  (filters: ActiveFilter[]) =>
  (query: QueryObject): QueryObject =>
    filters.reduce(
      (query, filter) => ({
        ...query,
        [filter.key]: filter.values.join(','),
      }),
      query,
    );

export const orderBy =
  ({ direction, property }: Ordering) =>
  (query: QueryObject): QueryObject => ({
    ...query,
    $orderBy: `${property.toString()}:${direction}`,
  });

export const paginate =
  ({ pageIndex, pageSize }: Omit<Paging, 'totalLength'>) =>
  (query: QueryObject): QueryObject => ({
    ...query,
    $skip: (pageIndex - 1) * pageSize,
    $top: pageSize,
  });

export const search =
  (searchValue?: string) =>
  (query: QueryObject): QueryObject =>
    !searchValue ? query : { ...query, $search: searchValue };
