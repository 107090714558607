import { createFeatureSelector, createSelector } from '@ngrx/store';
import { taxDocsFeature, TaxDocsState } from './tax-docs.reducer';

const selectTaxDocsState = createFeatureSelector<TaxDocsState>('tax-docs');

export const {
  selectAvailableTypes,
  selectActiveTaxDocType,
  selectCollections,
  selectLoadingAvailableTypes,
  selectTaxDocsDownloadAllLoading,
} = taxDocsFeature;

export const hasTaxDocsItems = createSelector(selectTaxDocsState, ({ collections }) => collections.taxDocs.list.length > 0);
