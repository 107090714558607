import { PERSONAL_DETAILS_DATA_ADAPTER } from './personal-details-data-adapter.token';
import { PersonalDetailsEffects } from './personal-details.effects';
import { personalDetailsFeature } from './personal-details.reducer';
import { PersonalDetailsDataAdapter } from './personal-details-data-adapter.interface';
import { EnvironmentProviders, makeEnvironmentProviders, Type } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

export const providePersonalDetailsState = (dataAdapterClass: Type<PersonalDetailsDataAdapter>): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideEffects([PersonalDetailsEffects]),
    provideState(personalDetailsFeature),
    { provide: PERSONAL_DETAILS_DATA_ADAPTER, useClass: dataAdapterClass },
  ]);
