import { Draft, produce } from 'immer';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { collectionsActionsFactory } from './collections-actions.factory';
import { CollectionsProjector } from './collections-projector.type';
import { CollectionState } from './collection-state.type';
import { selectableCollectionItemGuard } from './selectable-collection-item.type';

type CollectionsActions<Collections extends Record<string, CollectionState>> = ReturnType<typeof collectionsActionsFactory<Collections>>;

export const collectionsReductionsFactory = <
  Collections extends Record<string, CollectionState>,
  Actions extends CollectionsActions<Collections>,
  State,
>(
  collectionsProjector: CollectionsProjector<State, Collections>,
) => {
  const reduceCollectionOnAllItemsOnPageSelectionSet: OnReducer<State, [Actions['setAllItemsOnPageSelection']]> = (
    state,
    { collectionName, selected, unselectablePredicate },
  ) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];
      const { list, selection } = collection;

      for (const item of list) {
        if (selectableCollectionItemGuard(item)) {
          (!unselectablePredicate || !unselectablePredicate(item)) && (selected ? selection.set(item.id, item) : selection.delete(item.id));
        }
      }
    });

  const reduceCollectionOnExcludeIdsSet: OnReducer<State, [Actions['setExcludeIds']]> = (state, { collectionName, excludeIds }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];

      collection.listParams.excludeIds = excludeIds;
    });

  const reduceCollectionOnItemSelectionToggle: OnReducer<State, [Actions['toggleItemSelection']]> = (state, { collectionName, item }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];
      const { selection } = collection;

      selection.has(item.id) ? selection.delete(item.id) : selection.set(item.id, item);
    });

  const reduceCollectionOnLoading: OnReducer<State, [Actions['loadCollection']]> = (state, { collectionName }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];

      collection.loading = true;
    });

  const reduceCollectionOnLoadingFailure: OnReducer<State, [Actions['collectionLoadingFailed']]> = (state, { collectionName }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];

      collection.loading = false;
    });

  const reduceCollectionOnPagingChange: OnReducer<State, [Actions['changePaging']]> = (state, { collectionName, paging }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];

      collection.listParams.paging = paging;
    });

  const reduceCollectionOnPagingReset: OnReducer<State, [Actions['resetPaging']]> = (state, { collectionName }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];

      collection.listParams.paging.pageIndex = 1;
    });

  const reduceCollectionOnReset: OnReducer<State, [Actions['resetCollection']]> = (state, { collectionName, ordering, pageSize }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];

      collection.list = [];
      collection.listParams.paging.pageIndex = 1;
      collection.listParams.paging.pageSize = pageSize;
      collection.listParams.paging.totalLength = 0;
      collection.listParams.ordering = ordering;
      collection.listParams.excludeIds = [];
      collection.listParams.searchValue = '';
      collection.loading = false;
      collection.selection = new Map();
    });

  const reduceCollectionOnSearch: OnReducer<State, [Actions['search']]> = (state, { collectionName, searchValue }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];

      collection.listParams.searchValue = searchValue;
    });

  const reduceCollectionOnSelectionReset: OnReducer<State, [Actions['resetSelection']]> = (state, { collectionName }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];

      collection.selection = new Map();
    });

  const reduceCollectionOnSort: OnReducer<State, [Actions['sort']]> = (state, { collectionName, ordering }) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];

      collection.listParams.ordering = ordering;
    });

  const reduceCollectionOnSuccessfulLoad: OnReducer<State, [Actions['collectionLoadedSuccessfully']]> = (
    state,
    { collectionName, collectionData },
  ) =>
    produce(state, (draft: Draft<State>) => {
      const collection = collectionsProjector(draft)[collectionName];
      const { items, totalLength } = collectionData;

      collection.list = items;
      collection.listParams.paging.totalLength = totalLength;
      collection.loading = false;
    });

  return {
    reduceCollectionOnAllItemsOnPageSelectionSet,
    reduceCollectionOnExcludeIdsSet,
    reduceCollectionOnItemSelectionToggle,
    reduceCollectionOnLoading,
    reduceCollectionOnLoadingFailure,
    reduceCollectionOnPagingChange,
    reduceCollectionOnPagingReset,
    reduceCollectionOnReset,
    reduceCollectionOnSearch,
    reduceCollectionOnSelectionReset,
    reduceCollectionOnSort,
    reduceCollectionOnSuccessfulLoad,
  };
};
