import { createSelector } from '@ngrx/store';

import { collectionsSelectorsFactory } from '@ciphr/utils/collections';

import { payrollApprovalFeature } from './payroll-approval.reducer';

const { selectPayrollApprovalState } = payrollApprovalFeature;
export const selectPayrollApprovalCollections = createSelector(selectPayrollApprovalState, (state) => state.collections);

export const {
  selectCollectionList,
  selectCollectionListLoading,
  selectCollectionListOrdering,
  selectCollectionListPaging,
  selectEmptyCollectionState,
} = collectionsSelectorsFactory(selectPayrollApprovalCollections);
