import { EnvironmentProviders, makeEnvironmentProviders, Type } from '@angular/core';
import { payslipsFeature } from '../lib/payslips.reducer';
import { PayslipsEffects } from '../lib/payslips.effects';
import { PayslipsDataAdapter } from '../lib/payslips-data-adapter.interface';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { PAYSLIPS_DATA_ADAPTER } from './payslips-data-adapter.token';
import { DatePipe } from '@angular/common';

export const providePayslipsState = (dataAdapterClass: Type<PayslipsDataAdapter>): EnvironmentProviders =>
  makeEnvironmentProviders([
    DatePipe,
    provideEffects([PayslipsEffects]),
    provideState(payslipsFeature),
    { provide: PAYSLIPS_DATA_ADAPTER, useClass: dataAdapterClass },
  ]);
