import { createFeature, createReducer, on } from '@ngrx/store';

import { AuthContext } from '@ciphr/core/auth-context/models';

import { authContextDataAdapterActions } from './actions/auth-context-data-adapter.actions';
import { authContextFeatureActions } from './actions/auth-context-feature.actions';

export interface AuthContextState {
  activeUserContext: AuthContext | null;
  activeManagerContext: AuthContext | null;
  contextsList: AuthContext[];
  managerContextsOnly: boolean;
}

export const initialState: AuthContextState = {
  activeUserContext: null,
  activeManagerContext: null,
  contextsList: [],
  managerContextsOnly: false,
};

export const authContextFeature = createFeature({
  name: 'authContext',
  reducer: createReducer(
    initialState,
    on(
      authContextDataAdapterActions.contextsListLoadedSuccessfully,
      authContextFeatureActions.setContextsList,
      (state, { contextsList }) => {
        const activeUserContext = contextsList.find((context) => context.defaultContext && !context.adminUserLoginName) || null;

        let activeManagerContext = contextsList.find((context) => context.defaultContext && context.adminUserLoginName) || null;

        if (!activeManagerContext) {
          activeManagerContext = contextsList.filter((context) => context.adminUserLoginName)[0] || null;
        }

        const hasUserContext = contextsList.filter((context) => !context.adminUserLoginName).length > 0;

        return {
          ...state,
          activeUserContext,
          activeManagerContext,
          contextsList,
          managerContextsOnly: !hasUserContext,
        };
      },
    ),
    on(authContextFeatureActions.selectActiveContext, authContextFeatureActions.selectActiveManagerContext, (state, { context }) => {
      if (state.managerContextsOnly) {
        return {
          ...state,
          activeManagerContext: context,
        };
      }

      return {
        ...state,
        activeUserContext: context,
      };
    }),
    on(authContextFeatureActions.setManagerContextsOnly, (state) => ({
      ...state,
      managerContextsOnly: true,
    })),
    on(authContextFeatureActions.resetManagerContext, (state) => ({
      ...state,
      managerContextsOnly: false,
    })),
  ),
});
