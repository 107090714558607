import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PaginatedData } from '@ciphr/utils/interfaces';
import { TaxDocsTypes, TaxDocumentsListItem } from '@ciphr/domains/tax-docs/models';

export const taxDocsDataAdapterActions = createActionGroup({
  source: 'Tax Docs Data Adapter',
  events: {
    'Loaded Types Successfully': props<{ types: TaxDocsTypes[] }>(),
    'Loaded Types Failed': emptyProps(),
    'Loaded Docs Successfully': props<{ taxDocsList: PaginatedData<TaxDocumentsListItem> }>(),
    'Loaded Docs Failed': emptyProps(),
    'Download Docs Successfully': emptyProps(),
    'Download Docs Failed': emptyProps(),
    'Download All Docs Successfully': emptyProps(),
    'Download All Docs Failed': emptyProps(),
  },
});
