import { createSelector, MemoizedSelector } from '@ngrx/store';

import { FilteringFeatureNames } from '@ciphr/shared/collections-filtering/models';
import { selectAppliedFiltersNumber } from '@ciphr/shared/collections-filtering/state';

import { CollectionsProjector } from './collections-projector.type';
import { CollectionState } from './collection-state.type';
import { EmptyCollectionStates } from './empty-collection-states.enum';

export const collectionsSelectorsFactory = <Collections extends Record<string, CollectionState>>(
  collectionsProjector: CollectionsProjector<Record<string, unknown>, Collections>,
) => {
  const selectCollection = <CollectionName extends keyof Collections>(collectionName: CollectionName) =>
    createSelector(
      (state: Record<string, unknown>) => collectionsProjector(state),
      (collections) => collections[collectionName],
    );

  const selectCollectionList = <CollectionName extends keyof Collections>(
    collectionName: CollectionName,
  ): MemoizedSelector<Collections, Collections[CollectionName]['list']> =>
    createSelector(selectCollection(collectionName), ({ list }) => list);

  const selectCollectionListLoading = <CollectionName extends keyof Collections>(
    collectionName: CollectionName,
  ): MemoizedSelector<Collections, Collections[CollectionName]['loading']> =>
    createSelector(selectCollection(collectionName), ({ loading }) => loading);

  const selectCollectionListOrdering = <CollectionName extends keyof Collections>(
    collectionName: CollectionName,
  ): MemoizedSelector<Collections, Collections[CollectionName]['listParams']['ordering']> =>
    createSelector(selectCollectionListParams(collectionName), ({ ordering }) => ordering);

  const selectCollectionListPaging = <CollectionName extends keyof Collections>(
    collectionName: CollectionName,
  ): MemoizedSelector<Collections, Collections[CollectionName]['listParams']['paging']> =>
    createSelector(selectCollectionListParams(collectionName), ({ paging }) => paging);

  const selectCollectionListParams = <CollectionName extends keyof Collections>(
    collectionName: CollectionName,
  ): MemoizedSelector<Collections, Collections[CollectionName]['listParams']> =>
    createSelector(selectCollection(collectionName), ({ listParams }) => listParams);

  const selectCollectionListSearchValue = <CollectionName extends keyof Collections>(
    collectionName: CollectionName,
  ): MemoizedSelector<Collections, Collections[CollectionName]['listParams']['searchValue']> =>
    createSelector(selectCollectionListParams(collectionName), ({ searchValue }) => searchValue);

  const selectCollectionSelectedIds = <CollectionName extends keyof Collections>(collectionName: CollectionName) =>
    createSelector(selectCollectionSelection(collectionName), (selection) => [...selection.keys()]);

  const selectCollectionSelectedItems = <CollectionName extends keyof Collections>(collectionName: CollectionName) =>
    createSelector(selectCollectionSelection(collectionName), (selection) => [...selection.values()]);

  const selectCollectionSelection = <CollectionName extends keyof Collections>(
    collectionName: CollectionName,
  ): MemoizedSelector<Collections, Collections[CollectionName]['selection']> =>
    createSelector(selectCollection(collectionName), ({ selection }) => selection);

  const selectEmptyCollectionState = <CollectionName extends keyof Collections, FeatureName extends FilteringFeatureNames>(
    collectionName: CollectionName,
    featureName?: FeatureName,
  ) =>
    createSelector(
      selectCollection(collectionName),
      featureName ? selectAppliedFiltersNumber(featureName) : () => 0,
      ({ list, listParams, loading }, appliedFiltersNumber) => {
        if (!list.length && !loading) {
          return listParams.searchValue || appliedFiltersNumber ? EmptyCollectionStates.NoResults : EmptyCollectionStates.NoRecords;
        }

        return null;
      },
    );

  return {
    selectCollectionList,
    selectCollectionListLoading,
    selectCollectionListParams,
    selectCollectionListPaging,
    selectCollectionListOrdering,
    selectCollectionListSearchValue,
    selectCollectionSelectedIds,
    selectCollectionSelectedItems,
    selectCollectionSelection,
    selectEmptyCollectionState,
  };
};
