import { createFeature, createReducer } from '@ngrx/store';
import { TaxDocsTypes, TaxDocumentsListItem } from '@ciphr/domains/tax-docs/models';
import { taxDocsFeatureActions } from './actions/tax-docs-feature.actions';
import { taxDocsDataAdapterActions } from './actions/tax-docs-data-adapter.actions';
import { collectionsReductionsFactory, CollectionState, prepareCollectionInitialState } from '@ciphr/utils/collections';
import { immerOn } from 'ngrx-immer/store';
import { taxDocsCollectionsActions } from './actions/tax-docs-collections.actions';

export interface TaxDocsState {
  activeTaxDocType: TaxDocsTypes | null;
  availableTypes: TaxDocsTypes[] | null;
  loadingAvailableTypes: boolean;
  collections: {
    taxDocs: CollectionState<TaxDocumentsListItem>;
  };
  taxDocsDownloadAllLoading: boolean;
}

export const initialState: TaxDocsState = {
  activeTaxDocType: null,
  availableTypes: null,
  loadingAvailableTypes: false,
  collections: {
    taxDocs: prepareCollectionInitialState({ direction: 'desc', property: 'date' }),
  },
  taxDocsDownloadAllLoading: false,
};

const {
  reduceCollectionOnLoading,
  reduceCollectionOnLoadingFailure,
  reduceCollectionOnPagingChange,
  reduceCollectionOnPagingReset,
  reduceCollectionOnReset,
  reduceCollectionOnSort,
  reduceCollectionOnSuccessfulLoad,
} = collectionsReductionsFactory((state: TaxDocsState) => state.collections);

export const taxDocsFeature = createFeature({
  name: 'taxDcs',
  reducer: createReducer(
    initialState,
    immerOn(taxDocsCollectionsActions.loadCollection, reduceCollectionOnLoading),
    immerOn(taxDocsCollectionsActions.collectionLoadedSuccessfully, reduceCollectionOnSuccessfulLoad),
    immerOn(taxDocsCollectionsActions.collectionLoadingFailed, reduceCollectionOnLoadingFailure),
    immerOn(taxDocsCollectionsActions.resetCollection, reduceCollectionOnReset),

    immerOn(taxDocsCollectionsActions.changePaging, reduceCollectionOnPagingChange),
    immerOn(taxDocsCollectionsActions.resetPaging, reduceCollectionOnPagingReset),

    immerOn(taxDocsCollectionsActions.sort, reduceCollectionOnSort),

    immerOn(taxDocsFeatureActions.loadTypes, (state) => {
      state.loadingAvailableTypes = true;
    }),
    immerOn(taxDocsDataAdapterActions.loadedTypesSuccessfully, (state, { types }) => {
      state.loadingAvailableTypes = false;
      state.availableTypes = types;
    }),
    immerOn(taxDocsDataAdapterActions.loadedTypesFailed, (state) => {
      state.loadingAvailableTypes = false;
    }),
    immerOn(taxDocsFeatureActions.setActiveTaxDocType, (state, { taxDocType }) => {
      state.activeTaxDocType = taxDocType;
      state.collections.taxDocs.list = [];
      state.collections.taxDocs.listParams.paging.pageIndex = 1;
      state.collections.taxDocs.listParams.paging.pageSize = 10;
      state.collections.taxDocs.listParams.paging.totalLength = 0;
    }),
    immerOn(taxDocsFeatureActions.downloadDoc, (state) => {
      state.collections.taxDocs.loading = true;
    }),
    immerOn(taxDocsDataAdapterActions.downloadDocsFailed, taxDocsDataAdapterActions.downloadDocsSuccessfully, (state) => {
      state.collections.taxDocs.loading = false;
    }),
    immerOn(taxDocsFeatureActions.downloadAllDocs, (state) => {
      state.taxDocsDownloadAllLoading = true;
    }),
    immerOn(taxDocsDataAdapterActions.downloadAllDocsFailed, taxDocsDataAdapterActions.downloadAllDocsSuccessfully, (state) => {
      state.taxDocsDownloadAllLoading = false;
    }),
  ),
});
