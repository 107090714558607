import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PAYROLL_APPROVAL_DATA_ADAPTER } from './payroll-approval-data-adapter.token';
import { payrollApprovalFeatureActions } from './actions/payroll-approval-feature.actions';
import { catchError, filter, first, map, mergeMap, tap, throwError } from 'rxjs';
import { payrollApprovalDataAdapterActions } from './actions/payroll-approval-data-adapter.actions';
import { selectActiveCollection, selectCollections } from './payroll-approval.selectors';
import { authContextFeatureActions } from '@ciphr/core/auth-context/state';
import { collectionsEffectsFactory } from '@ciphr/utils/collections';

import { payrollApprovalCollectionsActions } from './actions/payroll-approvall-colletions.actions';
import { SnackBarService } from '@ciphr/ui';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class PayrollApprovalEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private dataAdapter = inject(PAYROLL_APPROVAL_DATA_ADAPTER);

  private snackbarService = inject(SnackBarService);
  private translocoService = inject(TranslocoService);

  private collectionsEffectsCreators = collectionsEffectsFactory(
    payrollApprovalCollectionsActions,
    selectCollections,
    PAYROLL_APPROVAL_DATA_ADAPTER,
  );

  loadApprovedPayrolls$ = this.collectionsEffectsCreators.prepareCollectionLoadEffect('approved', this.dataAdapter.fetchApprovedPayrolls);
  triggerApprovedPayrollsCollectionLoad$ = this.collectionsEffectsCreators.prepareCollectionLoadTriggerEffect('approved');

  loadPayrollsToApprove$ = this.collectionsEffectsCreators.prepareCollectionLoadEffect(
    'toApprove',
    this.dataAdapter.fetchPayrollsToApprove,
  );
  triggerPayrollsToApproveCollectionLoad$ = this.collectionsEffectsCreators.prepareCollectionLoadTriggerEffect('toApprove', {
    additionalActions: [payrollApprovalDataAdapterActions.payrollApprovedSuccessfully],
  });

  approvePayroll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payrollApprovalFeatureActions.approvePayroll),
      mergeMap(({ payroll }) =>
        this.dataAdapter.approvePayroll(payroll).pipe(
          tap(() =>
            this.snackbarService.open(
              this.translocoService.translate('PAYROLL_APPROVAL.APPROVE.SUCCESSFULLY', { displayName: payroll.monthlyName }),
              'success',
            ),
          ),
          map(() => payrollApprovalDataAdapterActions.payrollApprovedSuccessfully({ payroll })),
          catchError((httpResponseError) => {
            this.store.dispatch(payrollApprovalDataAdapterActions.payrollApprovedFailed());
            return throwError(() => httpResponseError);
          }),
        ),
      ),
    ),
  );

  resetPayrollApprovalLoadAfterCompanyContextChanged = createEffect(() =>
    this.actions$.pipe(
      ofType(authContextFeatureActions.selectActiveManagerContext),
      mergeMap(() => this.store.select(selectActiveCollection).pipe(filter(Boolean), first())),
      map((collectionName) =>
        payrollApprovalCollectionsActions.resetCollection({ collectionName, pageSize: 10, ordering: { direction: 'desc', property: '' } }),
      ),
    ),
  );

  triggerPayrollApprovalLoadAfterCompanyContextChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authContextFeatureActions.selectActiveManagerContext),
      mergeMap(() => this.store.select(selectActiveCollection).pipe(filter(Boolean), first())),
      map((collectionName) => payrollApprovalCollectionsActions.loadCollection({ collectionName })),
    ),
  );
}
