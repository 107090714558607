@if (!selectSensitiveDataVisibility()) {
  <chr-toast
    *transloco="let t"
    [title]="t('COMMON.SENSITIVE_DATA.TITLE')"
    data-test-id="alertContainer"
    (chr-close)="closeSensitiveDataToast()"
  >
    <span>{{ t("COMMON.SENSITIVE_DATA.DESCRIPTION") }}</span>
    <chr-button
      slot="footer"
      [label]="t('COMMON.LABELS.DISMISS')"
      variant="invisible"
      size="s"
      (chr-action)="closeSensitiveDataToast()"
      data-test-id="closeAlertContainerBtn"
    ></chr-button>
  </chr-toast>
}
