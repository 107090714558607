import { EnvironmentProviders, makeEnvironmentProviders, Type } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { TAX_DOCS_DATA_ADAPTER } from './tax-docs-data-adapter.token';
import { TaxDocsEffects } from './tax-docs.effects';
import { TaxDocsDataAdapter } from './tax-docs-data-adapter.interface';
import { taxDocsFeature } from './tax-docs.reducer';

export const provideTaxDocsState = (dataAdapterClass: Type<TaxDocsDataAdapter>): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideEffects([TaxDocsEffects]),
    provideState(taxDocsFeature),
    { provide: TAX_DOCS_DATA_ADAPTER, useClass: dataAdapterClass },
  ]);
