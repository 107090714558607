import { HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { TaxDocsDataAdapter } from '@ciphr/domains/tax-docs/state';
import { TaxDocsTypes, TaxDocumentsListItem } from '@ciphr/domains/tax-docs/models';
import { buildCollectionQuery, CollectionData, CollectionState, orderBy } from '@ciphr/utils/collections';
import { HTTP_API_CLIENT } from '@ciphr/utils/data-adapters';

@Injectable()
export class TaxDocsApiDataAdapterService implements TaxDocsDataAdapter {
  private httpClient = inject(HTTP_API_CLIENT);

  downloadTaxDoc(id: TaxDocumentsListItem['id'], type: TaxDocsTypes): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`/tax-docs/${type}/download/${id}`, { observe: 'response', responseType: 'blob' as 'json' });
  }

  downloadTaxDocs(type: TaxDocsTypes): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`/tax-docs/${type}/download`, { observe: 'response', responseType: 'blob' as 'json' });
  }

  fetchTaxDocsAvailableTypes(): Observable<TaxDocsTypes[]> {
    return this.httpClient.get<TaxDocsTypes[]>('/tax-docs/available-types');
  }

  fetchTaxDocumentsList(
    { ordering, paging }: CollectionState['listParams'],
    type: TaxDocsTypes,
  ): Observable<CollectionData<TaxDocumentsListItem>> {
    const queryParams = new HttpParams({
      fromObject: { ...buildCollectionQuery(orderBy(ordering)), $skip: (paging.pageIndex - 1) * paging.pageSize, $take: paging.pageSize },
    });

    return this.httpClient
      .get<{ items: TaxDocumentsListItem[]; length: number }>(`/tax-docs/${type}`, {
        params: queryParams,
      })
      .pipe(
        map(({ items, length }) => ({
          items,
          totalLength: length,
        })),
      );
  }
}
