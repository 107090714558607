import { isDevMode } from '@angular/core';

export const authConfig = {
  issuer: isDevMode() ? 'https://dev.ciphr.com' : window.location.origin,
  redirectUri: window.location.origin + '/mypay/app',
  responseType: 'code',
  scope: 'openid profile email offline_access https://api.mypay.ciphr.com/.default https://api.mypay-reporting.ciphr.com/.default',
  showDebugInformation: isDevMode(),
  useSilentRefresh: true,
  sessionChecksEnabled: true,
};
