import { createFeature, createReducer, on } from '@ngrx/store';

import { ListDateRangeParams } from '@ciphr/utils/interfaces';
import { Payslip, PayslipsListItem } from '@ciphr/domains/payslips/models';

import { payslipsDataAdapterActions } from './actions/payslips-data-adapter.actions';
import { payslipsFeatureActions } from './actions/payslips-feature.actions';
import { Ordering, Paging } from '@ciphr/utils/collections';

export interface PayslipState {
  activePayslip: Payslip | null;
  activePayslipLoading: boolean;
  currentPayslip: Payslip | null;
  currentPayslipLoading: boolean;
  payslipsList: PayslipsListItem[] | null;
  payslipsListLoading: boolean;
  payslipsListParams: { paging: Paging } & { orderBy: Ordering } & ListDateRangeParams;
  payslipsDownloadLoading: boolean;
}

export const initialState: PayslipState = {
  activePayslip: null,
  activePayslipLoading: false,
  currentPayslip: null,
  currentPayslipLoading: false,
  payslipsList: null,
  payslipsListLoading: false,
  payslipsListParams: {
    dateRange: {
      from: '2015-01-01',
      to: '2030-12-31',
    },
    paging: {
      pageIndex: 1,
      pageSize: 10,
      totalLength: 0,
    },
    orderBy: {
      property: 'date',
      direction: 'desc',
    },
  },
  payslipsDownloadLoading: false,
};

export const payslipsFeature = createFeature({
  name: 'payslips',
  reducer: createReducer(
    initialState,
    on(payslipsFeatureActions.loadCurrentPayslip, (state) => ({
      ...state,
      currentPayslipLoading: true,
    })),
    on(payslipsDataAdapterActions.currentPayslipLoadedSuccessfully, (state, { payslip }) => ({
      ...state,
      currentPayslipLoading: false,
      activePayslip: payslip,
      currentPayslip: payslip,
    })),
    on(payslipsDataAdapterActions.currentPayslipLoadedFailed, (state) => ({
      ...state,
      currentPayslipLoading: false,
    })),
    on(payslipsFeatureActions.clearCurrentPayslip, (state) => ({
      ...state,
      currentPayslip: null,
      activePayslip: null,
    })),
    on(payslipsFeatureActions.loadPayslipsList, (state) => ({
      ...state,
      payslipsListLoading: true,
    })),
    on(payslipsFeatureActions.clearPayslipsList, (state) => ({
      ...state,
      payslipsList: null,
    })),
    on(payslipsDataAdapterActions.payslipsListLoadedSuccessfully, (state, { payslipsList }) => ({
      ...state,
      payslipsListLoading: false,
      payslipsList: payslipsList.items,
      payslipsListParams: {
        ...state.payslipsListParams,
        paging: {
          ...state.payslipsListParams.paging,
          totalLength: payslipsList.length,
        },
      },
    })),
    on(payslipsDataAdapterActions.payslipsListLoadedFailed, (state) => ({
      ...state,
      payslipsListLoading: false,
      payslipsList: [],
    })),
    on(payslipsFeatureActions.loadPayslip, payslipsFeatureActions.downloadPayslip, (state) => ({
      ...state,
      activePayslipLoading: true,
    })),
    on(payslipsDataAdapterActions.payslipLoadedSuccessfully, (state, { payslip }) => ({
      ...state,
      activePayslip: payslip,
      activePayslipLoading: false,
    })),
    on(payslipsDataAdapterActions.payslipLoadedFailed, (state) => ({
      ...state,
      activePayslipLoading: false,
    })),
    on(payslipsDataAdapterActions.payslipDownloadedSuccessfully, payslipsDataAdapterActions.payslipDownloadFailed, (state) => ({
      ...state,
      activePayslipLoading: false,
    })),
    on(payslipsFeatureActions.downloadAllPayslips, (state) => ({
      ...state,
      payslipsDownloadLoading: true,
    })),
    on(payslipsDataAdapterActions.payslipsDownloadSuccessfully, payslipsDataAdapterActions.payslipsDownloadFailed, (state) => ({
      ...state,
      payslipsDownloadLoading: false,
    })),

    on(payslipsFeatureActions.changePayslipsListDateRange, (state, { from, to }) => ({
      ...state,
      payslipsListParams: {
        ...state.payslipsListParams,
        dateRange: {
          from,
          to,
        },
      },
    })),
    on(payslipsFeatureActions.changePayslipsListPage, (state, { pageIndex, pageSize }) => ({
      ...state,
      payslipsListParams: {
        ...state.payslipsListParams,
        paging: {
          ...state.payslipsListParams.paging,
          pageIndex,
          pageSize,
        },
      },
    })),
    on(payslipsFeatureActions.sortPayslipsList, (state, { orderBy }) => ({
      ...state,
      payslipsListParams: {
        ...state.payslipsListParams,
        orderBy,
      },
    })),
    on(payslipsFeatureActions.resetPayslipsListPaging, (state) => ({
      ...state,
      payslipsListParams: {
        ...state.payslipsListParams,
        paging: initialState.payslipsListParams.paging,
      },
    })),
  ),
});

export const { name, reducer, selectPayslipsState } = payslipsFeature;
