import { EnvironmentProviders, makeEnvironmentProviders, Type } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { APP_NAVIGATION_DATA_ADAPTER } from './app-navigation-data-adapter.token';
import { AppNavigationDataAdapter } from './app-navigation-data-adapter.interface';
import { AppNavigationEffects } from './app-navigation.effects';
import { appNavigationFeature } from './app-navigation.reducer';

export const provideAppNavigationState = (dataAdapterClass: Type<AppNavigationDataAdapter>): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideEffects([AppNavigationEffects]),
    provideState(appNavigationFeature),
    { provide: APP_NAVIGATION_DATA_ADAPTER, useClass: dataAdapterClass },
  ]);
