import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Payslip, PayslipHttpErrorResponse, PayslipsListItem } from '@ciphr/domains/payslips/models';

export const payslipsDataAdapterActions = createActionGroup({
  source: 'Payslips Data Adapter',
  events: {
    'Current Payslip Loaded Successfully': props<{ payslip: Payslip | null }>(),
    'Current Payslip Loaded Failed': emptyProps(),
    'Payslip Loaded Successfully': props<{ payslip: Payslip }>(),
    'Payslip Loaded Failed': emptyProps(),
    'Payslips List Loaded Successfully': props<{ payslipsList: { items: PayslipsListItem[]; length: number } }>(),
    'Payslips List Loaded Failed': props<{ error: PayslipHttpErrorResponse['error'] }>(),
    'Payslip Downloaded Successfully': emptyProps(),
    'Payslip Download Failed': emptyProps(),
    'Payslips Download Successfully': emptyProps(),
    'Payslips Download Failed': emptyProps(),
  },
});
