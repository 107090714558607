import { EnvironmentProviders, inject, Injectable, InjectionToken, makeEnvironmentProviders, Provider } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HTTP_API_CLIENT } from '@ciphr/utils/data-adapters';

import { PROFILE_DATA_ADAPTER } from './profile-data-adapter.token';
import { Profile } from '../models/profile.model';
import { ProfileDataAdapter } from './profile-data-adapter.interface';

@Injectable()
class ProfileApiDataAdapter implements ProfileDataAdapter {
  private readonly httpApiClient = inject(HTTP_API_CLIENT);

  fetchProfile(): Observable<Profile> {
    return this.httpApiClient.get<Profile>('/me');
  }
}

export const provideProfileApiDataAdapter = (httpApiClientToken?: InjectionToken<HttpClient>): EnvironmentProviders => {
  const providers: Provider[] = [{ provide: PROFILE_DATA_ADAPTER, useClass: ProfileApiDataAdapter }];

  httpApiClientToken && providers.push({ provide: HTTP_API_CLIENT, useExisting: httpApiClientToken });

  return makeEnvironmentProviders(providers);
};
