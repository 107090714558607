import { inject, Injectable, signal } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private oauthService = inject(OAuthService);

  readonly isSsoAdmin = signal(false);
  readonly issuer = this.oauthService.issuer;
  readonly username = signal('');

  readonly tokenRefreshed$ = this.oauthService.events.pipe(filter((event) => event.type === 'token_refreshed'));

  constructor() {
    const claims = this.oauthService.getIdentityClaims();

    this.oauthService.events.pipe(filter((e) => e.type === 'session_terminated')).subscribe(() => this.oauthService.initLoginFlow());
    this.oauthService.setupAutomaticSilentRefresh();

    this.isSsoAdmin.set(claims['isSsoAdmin'] === 'True');
    this.username.set(claims['preferred_username']);
  }

  logOut(): void {
    this.oauthService.logOut();
  }
}
