import { EnvironmentProviders, makeEnvironmentProviders, Type } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { SensitiveDataToggleEffects } from './sensitive-data-toggle.effects';
import { SensitiveDataToggleAdapter } from './sensitive-data-toggle-adapter.interface';
import { SENSITIVE_DATA_TOGGLE_ADAPTER } from './sensitive-data-toggle-adapter.token';
import { sensitiveDataFeature } from './sensitive-data-toggle.reducer';

export const provideSensitiveDataState = (dataAdapterClass: Type<SensitiveDataToggleAdapter>): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideEffects(SensitiveDataToggleEffects),
    provideState(sensitiveDataFeature),
    { provide: SENSITIVE_DATA_TOGGLE_ADAPTER, useClass: dataAdapterClass },
  ]);
