import { EnvironmentProviders, makeEnvironmentProviders, Type } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { PAYROLL_APPROVAL_DATA_ADAPTER } from './payroll-approval-data-adapter.token';
import { PayrollApprovalDataAdapter } from './payroll-approval-data-adapter.interface';
import { PayrollApprovalEffects } from './payroll-approval.effects';
import { payrollApprovalFeature } from './payroll-approval.reducer';

export const providePayrollApprovalState = (dataAdapterClass: Type<PayrollApprovalDataAdapter>): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideEffects([PayrollApprovalEffects]),
    provideState(payrollApprovalFeature),
    { provide: PAYROLL_APPROVAL_DATA_ADAPTER, useClass: dataAdapterClass },
  ]);
