import { createSelector } from '@ngrx/store';

import { authContextFeature } from './auth-context.reducer';

export const { selectActiveUserContext, selectActiveManagerContext, selectManagerContextsOnly, selectAuthContextState } =
  authContextFeature;

export const selectAvailableContexts = createSelector(selectAuthContextState, ({ contextsList, managerContextsOnly }) => {
  return managerContextsOnly
    ? contextsList.filter((context) => context.adminUserLoginName)
    : contextsList.filter((context) => !context.adminUserLoginName);
});

export const selectAvailableContextsAmount = createSelector(selectAvailableContexts, (contexts) => contexts.length);

export const selectActiveContext = createSelector(selectAuthContextState, (contexts) =>
  contexts.managerContextsOnly ? contexts.activeManagerContext : contexts.activeUserContext,
);

