import { inject } from '@angular/core';
import { Observable, retry, tap } from 'rxjs';

import { AppPermissionsService } from './app-permissions.service';
import { APP_PERMISSIONS_HTTP_CLIENT } from './app-permissions-http-client.token';

export const appPermissionsInitializer = () => {
  const httpClient = inject(APP_PERMISSIONS_HTTP_CLIENT);
  const appPermissionService = inject(AppPermissionsService);

  return (): Observable<Array<string>> =>
    httpClient.get<Array<string>>('/permissions').pipe(
      retry({ count: 10, delay: 500 }),
      tap((permissions) => appPermissionService.setPermissions(permissions)),
    );
};
