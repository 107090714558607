import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PayrollApprovalsStatus, PayrollListItem } from '@ciphr/domains/payroll-approval/models';

export const payrollApprovalFeatureActions = createActionGroup({
  source: 'Payroll Approval Feature',
  events: {
    'Set Active Collection': props<{ collection: PayrollApprovalsStatus }>(),
    'Approve Payroll': props<{ payroll: PayrollListItem }>(),
    'Reset Payroll Approvals': emptyProps(),
  },
});
