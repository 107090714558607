import { Directive, OnInit, TemplateRef, ViewContainerRef, inject, input } from '@angular/core';

import { PageNotAllowedComponent } from '@ciphr/shared/page-not-allowed';

import { AppPermissionsService } from './app-permissions.service';

@Directive({
  selector: '[ciphrAppPermissions]',
  standalone: true,
})
export class AppPermissionsDirective implements OnInit {
  private appPermissionService = inject(AppPermissionsService);
  private templateRef = inject(TemplateRef);
  private viewContainerRef = inject(ViewContainerRef);

  renderPageNotAllowed = input<boolean>(false, { alias: 'ciphrAppPermissionsRenderPageNotAllowed' });
  requiredPermission = input.required<string>({ alias: 'ciphrAppPermissions' });

  ngOnInit() {
    const hasPermission = this.appPermissionService.hasPermission(this.requiredPermission());

    if (hasPermission) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
      if (this.renderPageNotAllowed()) {
        this.viewContainerRef.createComponent(PageNotAllowedComponent);
      }
    }
  }
}
