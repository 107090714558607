@if (hasMultipleContext()) {
  <chr-select
    icon="user"
    data-test-id="companyContextSwitch"
    [ngModel]="JSON.stringify(activeContext())"
    (ngModelChange)="selectActiveContext($event)"
  >
    @for (context of contexts(); track context) {
      <option data-test-id="companyContextOption" [selected]="activeContext() === context" [value]="JSON.stringify(context)">
        {{ context | authContextLabel }}
      </option>
    }
  </chr-select>
}

