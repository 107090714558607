import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable } from 'rxjs';

import { OAuthStorage } from 'angular-oauth2-oidc';

export const authInterceptor =
  (apiUrls: string[]) =>
  (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const oAuthStorage = inject(OAuthStorage);

    const apiRequest = apiUrls.some((apiUrl) => req.url.startsWith(apiUrl));
    const authenticatedReq = req.clone({
      headers: req.headers.append('Authorization', `Bearer ${oAuthStorage.getItem('access_token')}`),
    });

    return next(apiRequest ? authenticatedReq : req);
  };
