import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TAX_DOCS_DATA_ADAPTER } from './tax-docs-data-adapter.token';
import { catchError, filter, map, mergeMap, switchMap, tap, throwError, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectActiveTaxDocType, selectCollections } from './tax-docs.selectors';
import { taxDocsFeatureActions } from './actions/tax-docs-feature.actions';
import { taxDocsDataAdapterActions } from './actions/tax-docs-data-adapter.actions';
import { FileService } from '@ciphr/shared/file-service';
import { authContextFeatureActions } from '@ciphr/core/auth-context/state';
import { Router } from '@angular/router';
import { collectionsEffectsFactory } from '@ciphr/utils/collections';

import { taxDocsCollectionsActions } from './actions/tax-docs-collections.actions';

@Injectable()
export class TaxDocsEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private dataAdapter = inject(TAX_DOCS_DATA_ADAPTER);

  private fileService = inject(FileService);
  private router = inject(Router);

  private collectionsEffectsCreators = collectionsEffectsFactory(taxDocsCollectionsActions, selectCollections, TAX_DOCS_DATA_ADAPTER);

  loadTaxDocs$ = this.collectionsEffectsCreators.prepareCollectionLoadEffect('taxDocs', this.dataAdapter.fetchTaxDocumentsList, {
    additionalFetchParamsSelectors: [selectActiveTaxDocType],
  });

  triggerTaxDocsCollectionLoad$ = this.collectionsEffectsCreators.prepareCollectionLoadTriggerEffect('taxDocs');
  resetTaxDocsCollectionPaging$ = this.collectionsEffectsCreators.preparePagingResetEffect('taxDocs');

  loadTaxDocsTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taxDocsFeatureActions.loadTypes),
      mergeMap(() =>
        this.dataAdapter.fetchTaxDocsAvailableTypes().pipe(
          map((taxDocsTypes) => taxDocsDataAdapterActions.loadedTypesSuccessfully({ types: taxDocsTypes })),
          catchError((httpResponseError) => {
            this.store.dispatch(taxDocsDataAdapterActions.loadedTypesFailed());
            return throwError(() => httpResponseError);
          }),
        ),
      ),
    ),
  );

  triggerTaxDocsListLoadingAfterCompanyContextChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authContextFeatureActions.selectActiveContext),
      filter(() => this.router.url.startsWith('/tax-docs')),
      map(() => taxDocsFeatureActions.loadTypes()),
    ),
  );

  redirectUserToAvailableTaxDocTypeWhenAfterContextChangeCurrentNotExist$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(taxDocsDataAdapterActions.loadedTypesSuccessfully),
        withLatestFrom(this.store.select(selectActiveTaxDocType).pipe(filter(Boolean))),
        tap(([{ types }, activeType]) => {
          if (!types.includes(activeType) && this.router.url.startsWith('/tax-docs')) {
            this.router.navigate(['tax-docs', types[0]]);
          }
        }),
      ),
    { dispatch: false },
  );

  loadDocs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taxDocsFeatureActions.setActiveTaxDocType),
      filter(Boolean),
      map(() => taxDocsCollectionsActions.loadCollection({ collectionName: 'taxDocs' })),
    ),
  );

  downloadAllTaxDocs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taxDocsFeatureActions.downloadAllDocs),
      withLatestFrom(this.store.select(selectActiveTaxDocType).pipe(filter(Boolean))),
      switchMap(([, taxDocType]) =>
        this.dataAdapter.downloadTaxDocs(taxDocType).pipe(
          tap(({ headers, body }) => this.fileService.downloadFileFromResponseBlob(headers, body)),
          map(() => taxDocsDataAdapterActions.downloadAllDocsSuccessfully()),
          catchError((httpResponseError) => {
            this.store.dispatch(taxDocsDataAdapterActions.downloadAllDocsFailed());
            return throwError(() => httpResponseError);
          }),
        ),
      ),
    ),
  );

  downloadTaxDoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taxDocsFeatureActions.downloadDoc),
      withLatestFrom(this.store.select(selectActiveTaxDocType).pipe(filter(Boolean))),
      mergeMap(([{ id }, taxDocType]) =>
        this.dataAdapter.downloadTaxDoc(id, taxDocType).pipe(
          tap(({ headers, body }) => this.fileService.downloadFileFromResponseBlob(headers, body)),
          map(() => taxDocsDataAdapterActions.downloadDocsSuccessfully()),
          catchError((httpResponseError) => {
            this.store.dispatch(taxDocsDataAdapterActions.downloadDocsFailed());
            return throwError(() => httpResponseError);
          }),
        ),
      ),
    ),
  );
}
