import { payslipsFeature, PayslipState } from './payslips.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const {
  selectActivePayslip,
  selectActivePayslipLoading,
  selectCurrentPayslip,
  selectCurrentPayslipLoading,
  selectPayslipsList,
  selectPayslipsListLoading,
  selectPayslipsListParams,
  selectPayslipsDownloadLoading,
} = payslipsFeature;

const selectPayslipsState = createFeatureSelector<PayslipState>('payslips');

export const hasPayslips = createSelector(selectPayslipsState, ({ payslipsList }) => (payslipsList ? payslipsList.length > 0 : null));
