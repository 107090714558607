import { inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { APP_NAVIGATION_HTTP_CLIENT } from './app-navigation-http-client.token';

import { NavigationItem } from '@ciphr/core/app-navigation/models';
import { AppNavigationDataAdapter } from '@ciphr/core/app-navigation/state';
import { buildCollectionQuery, search } from '@ciphr/utils/collections';

@Injectable()
export class AppNavigationApiDataAdapterService implements AppNavigationDataAdapter {
  private http = inject(APP_NAVIGATION_HTTP_CLIENT);

  fetchNavigation(mobileVersion: boolean, searchValue?: string): Observable<NavigationItem[]> {
    const queryParams = new HttpParams({ fromObject: buildCollectionQuery(search(searchValue ?? '')) });
    return this.http.get<NavigationItem[]>(`/navigation${mobileVersion ? '/mobile' : ''}`, { params: queryParams });
  }
}
