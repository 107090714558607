import { createFeature, createReducer, on } from '@ngrx/store';

import { PayrollApprovalsStatus, PayrollListItem } from '@ciphr/domains/payroll-approval/models';

import { payrollApprovalDataAdapterActions } from './actions/payroll-approval-data-adapter.actions';
import { payrollApprovalFeatureActions } from './actions/payroll-approval-feature.actions';
import { collectionsReductionsFactory, CollectionState, prepareCollectionInitialState } from '@ciphr/utils/collections';

import { immerOn } from 'ngrx-immer/store';
import { payrollApprovalCollectionsActions } from './actions/payroll-approvall-colletions.actions';

export interface PayrollApprovalState {
  activeCollection: PayrollApprovalsStatus | null;
  collections: {
    approved: CollectionState<PayrollListItem>;
    toApprove: CollectionState<PayrollListItem>;
  };
}

export const initialState: PayrollApprovalState = {
  activeCollection: null,
  collections: {
    approved: prepareCollectionInitialState({ direction: 'desc', property: '' }),
    toApprove: prepareCollectionInitialState({ direction: 'desc', property: '' }),
  },
};

const {
  reduceCollectionOnLoading,
  reduceCollectionOnLoadingFailure,
  reduceCollectionOnPagingChange,
  reduceCollectionOnPagingReset,
  reduceCollectionOnReset,
  reduceCollectionOnSort,
  reduceCollectionOnSuccessfulLoad,
} = collectionsReductionsFactory((state: PayrollApprovalState) => state.collections);

export const payrollApprovalFeature = createFeature({
  name: 'payrollApproval',
  reducer: createReducer(
    initialState,
    immerOn(payrollApprovalCollectionsActions.loadCollection, reduceCollectionOnLoading),
    immerOn(payrollApprovalCollectionsActions.collectionLoadedSuccessfully, reduceCollectionOnSuccessfulLoad),
    immerOn(payrollApprovalCollectionsActions.collectionLoadingFailed, reduceCollectionOnLoadingFailure),
    immerOn(payrollApprovalCollectionsActions.resetCollection, reduceCollectionOnReset),

    immerOn(payrollApprovalCollectionsActions.changePaging, reduceCollectionOnPagingChange),
    immerOn(payrollApprovalCollectionsActions.resetPaging, reduceCollectionOnPagingReset),

    immerOn(payrollApprovalCollectionsActions.sort, reduceCollectionOnSort),

    immerOn(payrollApprovalFeatureActions.setActiveCollection, (state, { collection }) => {
      state.activeCollection = collection;
    }),
    immerOn(payrollApprovalFeatureActions.approvePayroll, (state) => {
      state.collections.toApprove.loading = true;
    }),
    immerOn(payrollApprovalDataAdapterActions.payrollApprovedSuccessfully, (state) => {
      state.collections.toApprove.loading = false;
    }),
    immerOn(payrollApprovalDataAdapterActions.payrollApprovedFailed, (state) => {
      state.collections.toApprove.loading = false;
    }),
    on(payrollApprovalFeatureActions.resetPayrollApprovals, (state) => ({ ...state, ...initialState })),
  ),
});
