import { createActionGroup, props } from '@ngrx/store';

import { CollectionData } from './collection-data.type';
import { CollectionItem } from './collection-item.type';
import { CollectionState } from './collection-state.type';
import { Ordering } from './ordering.type';
import { Paging } from './paging.type';
import { SelectableCollectionItem } from './selectable-collection-item.type';

export const collectionsActionsFactory = <
  Collections extends Record<string, CollectionState>,
  Name = keyof Collections,
  Item = CollectionItem<Collections[keyof Collections]>,
>(
  source: string,
) =>
  createActionGroup({
    source: `${source} Collections` as const,
    events: {
      changePaging: props<{ collectionName: Name; paging: Paging }>(),
      collectionLoadedSuccessfully: props<{ collectionData: CollectionData<Item>; collectionName: Name }>(),
      collectionLoadingFailed: props<{ collectionName: Name }>(),
      loadCollection: props<{ collectionName: Name }>(),
      resetCollection: props<{ collectionName: Name; ordering: Ordering; pageSize: Paging['pageSize'] }>(),
      resetSelection: props<{ collectionName: Name }>(),
      resetPaging: props<{ collectionName: Name }>(),
      search: props<{ collectionName: Name; searchValue: string }>(),
      setExcludeIds: props<{ collectionName: Name; excludeIds: Array<number> }>(),
      setAllItemsOnPageSelection: props<{
        collectionName: Name;
        selected: boolean;
        unselectablePredicate?: (item: SelectableCollectionItem) => boolean;
      }>(),
      sort: props<{ collectionName: Name; ordering: Ordering }>(),
      toggleItemSelection: props<{ collectionName: Name; item: SelectableCollectionItem }>(),
    },
  });
