import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { PayrollApprovalDataAdapter } from '@ciphr/domains/payroll-approval/state';
import { PayrollListItem } from '@ciphr/domains/payroll-approval/models';

import { CollectionData, CollectionState } from '@ciphr/utils/collections';
import { HTTP_API_CLIENT } from '@ciphr/utils/data-adapters';

@Injectable()
export class PayrollApprovalApiDataAdapterService implements PayrollApprovalDataAdapter {
  private httpClient = inject(HTTP_API_CLIENT);

  fetchApprovedPayrolls({ paging }: CollectionState['listParams']): Observable<CollectionData<PayrollListItem>> {
    return this.fetchPayrollApprovals(true, paging);
  }

  fetchPayrollsToApprove({ paging }: CollectionState['listParams']): Observable<CollectionData<PayrollListItem>> {
    return this.fetchPayrollApprovals(false, paging);
  }

  approvePayroll(payrollListItem: PayrollListItem): Observable<PayrollListItem> {
    return this.httpClient.patch<PayrollListItem>(`/payroll-approvals/${payrollListItem.id}`, {
      approved: !payrollListItem.approved,
    });
  }

  private fetchPayrollApprovals(
    approved: boolean,
    paging: CollectionState['listParams']['paging'],
  ): Observable<CollectionData<PayrollListItem>> {
    return this.httpClient
      .get<{ items: PayrollListItem[]; length: number }>('/payroll-approvals', {
        params: new HttpParams({
          fromObject: {
            approved: approved,
            ...{ $skip: (paging.pageIndex - 1) * paging.pageSize, $take: paging.pageSize },
          },
        }),
      })
      .pipe(
        map(({ items, length }) => ({
          items,
          totalLength: length,
        })),
      );
  }
}
