import { Component } from '@angular/core';
import { NavigationComponent } from './navigation/navigation.component';
import { RouterOutlet } from '@angular/router';
import { SensitiveDataAlertComponent } from '@ciphr/shared/sensitive-data-toggle/features';
@Component({
  selector: 'mypay-root',
  standalone: true,
  imports: [NavigationComponent, RouterOutlet, SensitiveDataAlertComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {}
