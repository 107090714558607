import { Ordering } from './ordering.type';

export const prepareCollectionInitialState = (ordering: Ordering, pageSize = 10) => ({
  list: [],
  listParams: {
    ordering,
    paging: {
      pageIndex: 1,
      pageSize,
      totalLength: 0,
    },
    searchValue: '',
  },
  loading: true,
  selection: new Map(),
});
