import { ErrorHandler, inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { TranslocoService } from '@ngneat/transloco';

import { SnackBarService } from '@ciphr/ui';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  private snackbarService = inject(SnackBarService);
  private translocoService = inject(TranslocoService);

  handleError(error: unknown) {
    if (error instanceof HttpErrorResponse) {
      let errorMessage = 'COMMON.SNACK_BAR.HTTP_ERROR.MESSAGE';

      if (error.status === HttpStatusCode.BadRequest) {
        errorMessage = error.error.details.find((detail: { code: 'ERROR'; message: string }) => detail.code === 'ERROR')?.message;
      }

      this.snackbarService.open(this.translocoService.translate(errorMessage), 'error');
    } else {
      console.error(error);
    }
  }
}
